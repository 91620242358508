// @import "~antd/dist/antd.less";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-caf-primary: #004af7;
  --color-caf-primary-dark: #012989;
  --color-caf-primary-light: #d9e5ff;
  --color-caf-primary-shadow: #e6edff;

  --color-primary: #bdbdbd;
  --color-primary-light: #e0e0e0;

  --color-secondary: #bdbdbd;
  --color-secondary-light: #e0e0e0;

  --color-illustrations: var(--color-primary-light);
  --color-illustrations-dark: var(--color-primary);

  --color-headings: var(--color-primary);
  --color-subheadings: var(--color-primary);

  --color-next-button-text: #ffffff;

  --color-other-texts: #1f2937;

  --color-button-background: var(--color-secondary);
  --color-button-text: #ffffff;

  --color-back-button-icon: var(--color-primary);
  --color-back-button: var(--color-primary);
  --color-back-button-border: var(--color-primary);

  --color-tips-icon: var(--color-primary);
  --color-tips-background: var(--color-primary);
  --color-tips-text: var(--color-primary);

  --color-progressbar: var(--color-primary);

  --font-family: "Roboto", sans-serif;
}

html,
body {
  height: 100%;
  font-family: var(--font-family) !important;
  background-color: transparent !important;

  &.openModal {
    overflow-y: hidden;
  }
}

*[data-customizable] {
  cursor: pointer !important;
  position: relative;
  overflow: visible;
}
*[data-customizable]:not(*[data-customizable-path])::before {
  content: "";
  display: block;
  position: absolute;
  top: -3px;
  bottom: -3px;
  left: -3px;
  right: -3px;
}
*[data-customizable-path]::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 5px;
  opacity: 0.7;
  border: 1px dashed lightgray !important;
  transition: border 0.05s !important;
}
*[data-customizable]:not([data-customizable-minimalist="true"]):not(*[data-customizable-path]):before {
  border-radius: 5px;
  opacity: 0.7;
  border: 1px dashed lightgray !important;
  transition: border 0.05s !important;
}
*[data-customizable]:not([data-customizable-minimalist="true"]):not(*[data-customizable-path]):hover::before,
*[data-customizable]:not([data-customizable-minimalist="true"]):not(*[data-customizable-path]).active::before {
  content: "";
  display: block;
  position: absolute;
  top: -3px;
  bottom: -3px;
  left: -3px;
  right: -3px;
  border-radius: 5px;
  opacity: 0.5;
  border: 2px dashed var(--color-caf-primary) !important;
  transition: border 0.15s !important;
}
*[data-customizable-path]:hover::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 5px;
  opacity: 0.5;
  border: 2px dashed var(--color-caf-primary) !important;
  transition: border 0.15s !important;
}

*[data-customizable]:not([data-customizable-minimalist="true"])::after {
  content: "";
  position: absolute;
  top: -12px;
  right: -12px;
  width: 24px;
  height: 24px;
  border: 1px solid #ffffff;
  background-color: var(--color-caf-primary);
  background-image: url("~@assets/icons/edit.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  border-radius: 100%;
  opacity: 0.8;
}

.customizable-footer::after {
  right: 0px !important;
}

::selection {
  color: #ffffff !important;
  background: var(--color-caf-primary) !important;
}

.themed-svg svg path {
  &.light {
    fill: var(--color-illustrations);
  }
  &.dark {
    fill: var(--color-illustrations-dark);
  }
}

// <AutoCompleteInput />
#react-autocomplete-text-input {
  &:focus,
  &:active,
  &:hover {
    border-color: var(--color-caf-primary) !important;
    transition: border-color 0.3s ease-in-out;
  }
}

.react-autocomplete-input {
  font-family: var(--font-family) !important;
  border-radius: 0px 0px 8px 8px !important;
  border: none !important;
  font-weight: 500;
  max-height: 215px !important;
  max-width: 250px !important;
  overflow-y: auto;
  overflow-x: hidden;

  li {
    font-size: 13px !important;
    min-width: 80px !important;
    width: 100% !important;
    padding: 4px 6px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.active {
      background-color: var(--color-caf-primary) !important;
      color: #ffffff;
    }
  }
}

.validation-notifications-icon {
  &:not(.default) {
    background-color: #ffffff;
    border-radius: 50%;
  }

  &.default svg path {
    color: #ffffff;
  }

  &.warning {
    padding: 4px;
    svg path {
      color: #f8b239;
    }
  }

  &.error {
    padding: 2px;

    svg path {
      color: #e21b45;
    }
  }
}

/** Responsiveness */
@media (max-height: 800px) and (min-width: 24rem) {
  html,
  body {
    font-size: 95%;
  }
}
@media (max-height: 700px) and (min-width: 24rem) {
  html,
  body {
    font-size: 90%;
  }
}
@media (max-height: 600px) and (min-width: 24rem) {
  html,
  body {
    font-size: 85%;
  }
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

